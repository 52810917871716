import {
  ActivatedRoute,
} from '@angular/router';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { faPlayCircle, faRedoAlt, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import * as moment from 'moment';
import { FeatureAccessService } from 'src/app/common-services/feature-access.service';
import { isStoreLevelUser } from 'src/app/utils/user-roles/is-store-level-user';
import { getUserOrgLevel } from 'src/app/utils/user-roles/get-user-org-level';
import { isHigherThanStoreLevelUser } from 'src/app/utils/user-roles/is-higher-than-store-level-user';
import { MessagesComponent } from '../messages/messages.component';
import { StateService } from '../../common-services/state.service';
import { CsaAuthService } from '../../auth/csa-auth.service';
import { SortPipe } from '../../custom-pipes/sort.pipe';
import { User } from '../../models/user';
import { SurveysService } from '../surveys.service';

@Component({
  selector: 'app-results-table',
  templateUrl: './results-table.component.html',
  styleUrls: ['./results-table.component.scss'],
  providers: [
    SortPipe
  ]
})
export class ResultsTableComponent implements OnInit {
  @Input() surveyType: string;

  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  compState = (val1, val2) => val1 == val2;

  faSortDown = faSortDown;

  faPlayCircle = faPlayCircle;

  faRedoAlt = faRedoAlt;

  availableLocations: object = {};

  currentLevel: string = 'division';

  locations = '';

  fullFilter: string = '';

  groupedSurveysToShow: any[] = [];

  groupedTableColumns: any[] = [];

  hasNext: string;

  index: number = 0;

  locationsOrdered: string[] = [];

  nextLevel: string = '';

  noEntries: string = '';

  orgStructure: object;

  pageIndex: number = 0;

  pageSize: number = 10;

  saveState: boolean = false;

  searching: boolean = false;

  selectedLocations: object = {};

  selectedGroup: number = 0;

  selectedStore: object = { Store_No: '', Location_Name: 'All Stores' };

  showGroupedBreakdown: boolean = true;

  showLocations: object = {};

  storeName: string = '';

  storeNumber: string = '';

  storesReturned: object = [];

  surveyDetails: object = {};

  surveyGroups: string[];

  surveyID: string = '';

  surveysToShow: object[] = [];

  tableColumns: string[];

  tableGroups: string[];

  totalCount: number = 0;

  user: User;

  searchTimer;

  isLoadingGrouped = false;

  isLoadingIndividual = false;

  isStoreLevelUser = false;

  constructor(
    private actRoute: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private csaAuth: CsaAuthService,
    private dialog: MatDialog,
    private elementRef: ElementRef,
    private state: StateService,
    private sortPipe: SortPipe,
    private surveyService:SurveysService,
    public featureAccessService: FeatureAccessService
  ) { }

  ngOnInit(): void {
    this.surveyID = this.actRoute.snapshot.paramMap.get('id');
    this.user = this.csaAuth.user;
    console.log('ResultsTableComponent', this.csaAuth.user);
    this.isStoreLevelUser = isStoreLevelUser(this.csaAuth.user);
    console.log(`isStoreLevelUser: ${this.isStoreLevelUser}`);

    if (this.surveyType == 'mrr') {
      this.surveyGroups = ['New', 'Started', 'Closed'];
      this.tableGroups = ['active', 'started', 'closed'];
      // Opening or seeing a list of draft MRR assessments is granted with the mrrAssessments.viewDraftResponse feature.
      if (this.featureAccessService.hasAccess('mrrAssessments.viewDraftResponse')) {
        this.surveyGroups.unshift('Open');
        this.tableGroups.unshift('open');
      }
    } else {
      this.surveyGroups = ['New', 'Started', 'Submitted', 'Closed'];
      this.tableGroups = ['active', 'started', 'submitted', 'closed'];
    }

    if (this.state.state && this.state.origin == this.surveyID) {
      for (const key in this.state.state) {
        this[key] = this.state.state[key];
      }

      if (this.selectedStore['Location_Name'] != 'All Stores') {
        this.setStore(this.selectedStore);
      } else {
        this.getSurveys();
      }
    } else {
      const selection = this.initialFilters();
      this.surveyService
        .getAboveStoreSurveyID(this.surveyID, '')
        .subscribe((surveysData) => {
          this.surveyDetails = surveysData;
          this.getSurveysToShow(selection, this.currentLevel);
        });
    }
  }

  ngOnDestroy() {
    this.setState();
  }

  setState() {
    if (this.saveState) {
      this.state.origin = this.surveyID;
      this.state.state = {
        availableLocations: this.availableLocations,
        currentLevel: this.currentLevel,
        fullFilter: this.fullFilter,
        groupedSurveysToShow: this.groupedSurveysToShow,
        groupedTableColumns: this.groupedTableColumns,
        hasNext: this.hasNext,
        index: this.index,
        locationsOrdered: this.locationsOrdered,
        nextLevel: this.nextLevel,
        noEntries: this.noEntries,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        selectedLocations: this.selectedLocations,
        selectedGroup: this.selectedGroup,
        selectedStore: this.selectedStore,
        showGroupedBreakdown: this.showGroupedBreakdown,
        showLocations: this.showLocations,
        storeName: this.storeName,
        storeNumber: this.storeNumber,
        storesReturned: this.storesReturned,
        surveyDetails: this.surveyDetails,
        surveyID: this.surveyID,
        surveysToShow: this.surveysToShow,
        surveyType: this.surveyType,
        tableColumns: this.tableColumns,
        totalCount: this.totalCount,
      };
    } else {
      // Commented out to prevent parent state being overridden
      // this.state.origin = undefined;
      // this.state.state = undefined;
    }
  }

  showReset(): boolean {
    if (this.selectedStore['Location_Name'] != 'All Stores') {
      return true;
    }

    for (const key of Object.keys(this.selectedLocations)) {
      if (this.selectedLocations[key] && this.showLocations[key]) {
        return true;
      }
    }
    return false;
  }

  getPreviousLevel(level): string {
    return Object.keys(this.user['orgStruct']).find((key) => this.user['orgStruct'][key] == level);
  }

  resetFilters(): void {
    const selection = this.initialFilters();
    this.getSurveysToShow(selection, this.currentLevel);
  }

  initialFilters() {
    let selection = '';
    let key = 'division';
    while (this.user['orgStruct'][key]) {
      const value = this.user['orgStruct'][key];
      if (this.user[value]) {
        this.selectedLocations[value] = this.user[value];
      } else if (value == 'groupID') {
        this.selectedLocations[value] = this.user['groupNo'];
      } else {
        this.selectedLocations[value] = '';
      }

      this.showLocations[value] = false;
      if (this.locationsOrdered.indexOf(value) === -1) {
        this.locationsOrdered.push(value);
      }
      key = value;
    }

    if (this.user['storeID']) {
      this.storeName = this.user['storeName'];
      this.storeNumber = this.user['storeID'];
      this.selectedStore = { Store_No: this.user['storeID'], Location_Name: this.user['storeName'] };
    } else {
      this.storeName = '';
      this.storeNumber = '';
      this.selectedStore = { Store_No: '', Location_Name: 'All Stores' };
    }

    const userOrgLevel = getUserOrgLevel(this.user);
    if (userOrgLevel) {
      this.currentLevel = userOrgLevel['level'];
      selection = userOrgLevel['value'];
    }

    return selection;
  }

  resetPagination(): void {
    this.paginator.pageIndex = 0;
    this.index = 0;
    this.pageIndex = 0;
    this.pageSize = this.paginator.pageSize;
  }

  locationString(key): string {
    if (key == 'state' || key == 'region') return this.selectedLocations[key];
    if (key == 'groupID') return `Group ${this.selectedLocations[key]}`;
    return `${key} ${this.selectedLocations[key]}`;
  }

  locationStringAll(key): string {
    if (key == 'groupID') key = 'group';
    return `All ${key}s`;
  }

  resetSelectedBelowLevel(): void {
    let key = this.nextLevel;
    while (this.user['orgStruct'][key]) {
      this.selectedLocations[key] = '';
      this.showLocations[key] = (key == this.nextLevel);
      key = this.user['orgStruct'][key];
    }
  }

  getSurveysToShow(selection, level) {
    setTimeout(() => {
      this.isLoadingGrouped = true;
      this.groupedSurveysToShow = [];
    }, 0);

    if (this.surveyType == 'onceoff') {
      this.selectedStore = { Store_No: '', Location_Name: 'All Stores' };
    }

    if (this.surveyType == 'adhoc' || this.surveyType == 'mrr') {
      this.groupedTableColumns = ['responsesCreated', 'actions'];
    } else {
      this.groupedTableColumns = ['%Complete', 'actions'];
    }

    this.currentLevel = level;
    this.nextLevel = this.user['orgStruct'][level];

    this.resetSelectedBelowLevel();
    if (selection) {
      this.selectedLocations[this.currentLevel] = selection;
    }

    if (!this.nextLevel || this.nextLevel == 'storeID') {
      this.showGroupedBreakdown = false;
      this.isLoadingGrouped = false;
      this.getSurveys();
    } else {
      this.groupedTableColumns.unshift(this.nextLevel);
      this.showGroupedBreakdown = true;

      const [query1, query2] = this.createLevelQueries();
      if (this.user['orgStruct'][this.nextLevel]) {
        this.surveyService.getNextLevel(query1).subscribe((data) => {
          this.availableLocations[this.nextLevel] = this.sortPipe.transform(data[this.nextLevel]);
          this.surveyService
            .getDrillDownInfo(query2)
            .subscribe((surveysData) => {
              if (this.surveyDetails['targetStores']) {
                this.groupedSurveysToShow = surveysData['groupedData'];
              } else {
                const drillDownData = surveysData['groupedData'];
                this.groupedSurveysToShow = [];
                this.availableLocations[this.nextLevel].forEach((location) => {
                  let levelData = {};
                  const obj = drillDownData.find((obj) => obj[this.nextLevel] === location);
                  if (obj === undefined) {
                    levelData[this.nextLevel] = location;
                    levelData['avgCompletionRate'] = 0;
                    levelData['actionsCreated'] = 0;
                  } else {
                    levelData = obj;
                  }
                  this.groupedSurveysToShow.push(levelData);
                });
              }

              this.isLoadingGrouped = false;
              this.getSurveys();
            }, (error) => {
              this.isLoadingGrouped = false;
            });
        });
      } else if (this.surveyType == 'adhoc' || this.surveyType == 'mrr') {
        this.groupedTableColumns.unshift('store');
        this.surveyService
          .getDrillDownInfo(query2)
          .subscribe((surveysData) => {
            this.groupedSurveysToShow = surveysData['groupedData'];
            this.isLoadingGrouped = false;
            this.getSurveys();
          }, (error) => {
            this.isLoadingGrouped = false;
          });
      } else {
        this.getSurveys();
        this.isLoadingGrouped = false;
      }
    }

    this.cd.detectChanges();
  }

  createLevelQueries(): [string, string] {
    let key = 'division';
    let query = '';
    while (key != this.nextLevel) {
      if (key == 'division') {
        query = `?division=${this.user['division']}`;
      } else {
        query += `&${key}=${this.selectedLocations[key]}`;
      }
      key = this.user['orgStruct'][key];
    }

    return [
      query,
      `${this.nextLevel + (query == '' ? '?' : `${query}&`)}survey_id=${this.surveyID}`
    ];
  }

  createSurveyQuery(): string {
    let query = `?status=${
      this.tableGroups[this.selectedGroup]
    }&survey_id=${
      this.surveyID}`;

    if (this.surveyType != 'onceoff') {
      let surveyType = this.surveyType;
      if (surveyType == 'mrr') {
        surveyType = 'adhoc';
      }
      query += `&type=${ 
        surveyType}`;
    }

    if (this.storeNumber) {
      query += `&storeID=${this.storeNumber}`;
      return query;
    }

    // Replace with locations
    let key = this.user['orgStruct']['division'];
    while (key != this.nextLevel) {
      query += `&${key}=${this.selectedLocations[key]}`;
      key = this.user['orgStruct'][key];
    }
    return query;
  }

  getSurveys() {
    setTimeout(() => {
      this.isLoadingIndividual = true;
      this.surveysToShow = [];
    }, 0);

    switch (this.surveyType) {
      case 'onceoff':
        this.tableColumns = [
          'lastModified',
          this.tableGroups[this.selectedGroup] == 'submitted'
            ? 'completedBy'
            : '%Complete',
          'actions',
        ];
        break;

      case 'recurring':
        if (this.tableGroups[this.selectedGroup] == 'submitted') {
          this.tableColumns = ['completedBy'];
        } else if (this.tableGroups[this.selectedGroup] != 'closed') {
          this.tableColumns = ['lastModified', 'dueDate', '%Complete'];
        } else {
          this.tableColumns = ['lastModified', '%Complete'];
        }
        this.tableColumns.push('actions');
        break;

      case 'adhoc':
        if (this.tableGroups[this.selectedGroup] == 'submitted') {
          this.tableColumns = ['completedBy'];
        } else if (this.tableGroups[this.selectedGroup] != 'closed') {
          this.tableColumns = ['lastModified', 'dueDate', '%Complete'];
        } else {
          this.tableColumns = ['lastModified', '%Complete'];
        }
        this.tableColumns.push('actions');

        if (
          this.surveyDetails['targetRoles'].indexOf(this.user['role']) > -1
          && this.tableGroups[this.selectedGroup] != 'submitted'
        ) {
          this.tableColumns.push('status');
        }
        break;

      case 'mrr':
        if (this.tableGroups[this.selectedGroup] == 'open') {
          this.tableColumns = ['lastModified', 'dueDate', 'finding', 'status'];
        } else if (this.tableGroups[this.selectedGroup] == 'active') {
          this.tableColumns = ['lastModified', 'dueDate', 'finding', 'status'];
        } else if (this.tableGroups[this.selectedGroup] == 'submitted') {
          this.tableColumns = ['completedBy', 'finding'];
        } else if (this.tableGroups[this.selectedGroup] == 'started') {
          this.tableColumns = ['lastModified', 'dueDate', 'finding', 'actions', 'status'];
        } else if (this.tableGroups[this.selectedGroup] == 'closed') {
          this.tableColumns = ['lastModified', 'finding'];
        }
        break;

      default:
        break;
    }

    if (isHigherThanStoreLevelUser(this.user)) {
      this.tableColumns = ['store', ...this.tableColumns];
    }

    this.surveyService
      .getStoreSurveys(
        this.createSurveyQuery(),
        `/${this.index}`,
        `/${this.pageSize}`
      )
      .subscribe((surveysData) => {
        this.surveysToShow = surveysData['surveys'];
        this.signOffAbilityCheck();
        this.hasNext = surveysData['has_next'];
        this.getPagination();
        this.getSurveyStates(this.surveysToShow);
        this.isLoadingIndividual = false;
      }, (error) => {
        this.isLoadingIndividual = false;
      });
  }

  getSurveyStates(surveys) {
    for (let i = 0; i < surveys.length; i++) {
      const status = surveys[i].status;
      if (status == 'active') {
        surveys[i].surveyState = 'Start';
      } else if (status == 'started') {
        surveys[i].surveyState = 'Continue';
      } else if (status == 'submitted') {
        surveys[i].surveyState = 'Submitted';
      } else if (status == 'closed') {
        surveys[i].surveyState = 'Closed';
      } else if (status == 'open') {
        surveys[i].surveyState = 'Edit';
      }
    }
  }

  signOffAbilityCheck() {
    const features = ['mrrAssessments.signOffLowLevel', 'mrrAssessments.signOffHighLevel'];
    const userSignOffLevelFeatures = features.filter((feature: string) => this.featureAccessService.hasAccess(feature));

    for (let i = 0; i < this.surveysToShow.length; i++) {
      this.surveysToShow[i]['signoffbyFeature'] = false;
      if (this.surveysToShow[i]['signOff']) {
        if (this.surveysToShow[i]['signOff']['required']) {
          for (let j = 0; j < this.surveysToShow[i]['signOff']['details'].length; j++) {
            if (!this.surveysToShow[i]['signOff']['details'][j]['signedOff'] && this.surveysToShow[i]['signOff']['details'][j]['signOffLevel'] === 'high' && userSignOffLevelFeatures.includes('mrrAssessments.signOffHighLevel')) {
              this.surveysToShow[i]['signoffbyFeature'] = true;
            }
            if (!this.surveysToShow[i]['signOff']['details'][j]['signedOff'] && this.surveysToShow[i]['signOff']['details'][j]['signOffLevel'] === 'low' && userSignOffLevelFeatures.includes('mrrAssessments.signOffLowLevel')) {
              this.surveysToShow[i]['signoffbyFeature'] = true;
            }
            if (this.surveysToShow[i]['signoffbyFeature']) {
              console.log(
                'signOffAbilityCheck passed by ',
                this.surveysToShow[i]['signOff']['details']
              );              
            }
          }
        }
      }
    }
  }

  getNext(event: PageEvent) {
    this.index = event.pageIndex * event.pageSize;
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;

    if (this.selectedStore['Location_Name'] != 'All Stores') {
      this.setStore(this.selectedStore);
    } else {
      this.getSurveys();
    }
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent) {
    this.paginator.pageIndex = 0;
    this.pageIndex = 0;
    this.index = 0;
    this.pageSize = this.paginator.pageSize;
    this.selectedGroup = tabChangeEvent.index;

    if (this.selectedStore['Location_Name'] != 'All Stores') {
      this.setStore(this.selectedStore);
    } else {
      this.getSurveys();
    }
  }

  drillDownTo(survey) {
    this.getSurveysToShow(
      survey[this.user['orgStruct'][this.currentLevel]],
      this.user['orgStruct'][this.currentLevel]
    );
  }

  getPagination() {
    const label = this.elementRef.nativeElement.querySelector(
      '.mat-paginator-range-label'
    );
    if (label) {
      if (this.surveysToShow.length == 0) {
        label.innerHTML = 'No results to show';
        this.totalCount = 0;
      } else {
        const upToCount = this.index + this.surveysToShow.length;

        if (this.hasNext == 'Y') {
          label.innerHTML = `${String(this.index + 1)} to ${upToCount} of Many`;
          this.totalCount = upToCount + 1;
        } else {
          label.innerHTML = `${String(this.index + 1)} to ${upToCount} of ${upToCount}`;
          this.totalCount = upToCount;
        }
      }
    }
  }

  searchForStores(storeId) {
    this.noEntries = 'Searching...';
    this.searching = true;

    clearTimeout(this.searchTimer);
    this.searchTimer = setTimeout(() => {
      if (this.user['division'] == 'DC') {
        this.noEntries = 'No matching DCs found..';
      } else {
        this.noEntries = 'No matching Stores found..';
      }
      if (storeId.trim()) {
        this.surveyService.getStoreList(storeId).subscribe(
          (storeData) => {
            this.storesReturned = storeData;
            setTimeout(() => {
              this.searching = false;
            }, 1000);
          },
          (error) => {
            this.searching = false;
            this.dialog.open(MessagesComponent, {
              data: {
                heading: 'An Error Has Occured',
                message:
                  `An Error was returned when trying to find stores. Please email ${ 
                    this.user['supportEmail']
                  } for support`,
                closeText: 'Ok',
              },
              backdropClass: 'dialog-backdrop',
            });
          }
        );
      } else {
        this.storesReturned = [];
        this.searching = false;
      }
    }, 1000);
  }

  setStore(store) {
    if (store['Location_Name'] == 'All Stores') {
      this.showGroupedBreakdown = true;
      this.resetFilters();
    } else {
      setTimeout(() => {
        this.isLoadingIndividual = true;
        this.surveysToShow = [];
      }, 0);

      this.showGroupedBreakdown = false;
      this.selectedStore = store;
      this.storeNumber = store['Store_No'];
      this.storeName = store['Location_Name'];
      for (const key of Object.keys(this.showLocations)) {
        this.showLocations[key] = false;
      }

      this.surveyService
        .getStoreSurveys(
          this.createSurveyQuery(),
          `/${this.index}`,
          `/${this.pageSize}`
        ).subscribe((surveysData) => {
          this.surveysToShow = surveysData['surveys'];
          this.signOffAbilityCheck();
          this.hasNext = surveysData['has_next'];
          this.getPagination();
          if (this.surveyType == 'adhoc') {
            this.getSurveyStates(this.surveysToShow);
          }
          this.isLoadingIndividual = false;
        }, (error) => {
          this.isLoadingIndividual = false;
        });
    }
  }
}
