import {
  Component, ElementRef, OnInit, ViewChild 
} from '@angular/core';
import { Router } from '@angular/router';
import { faPlus, faCheckSquare, faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import { CsaAuthService } from 'src/app/auth/csa-auth.service';
import { LoaderService } from 'src/app/common-services/loader.service';
import { User } from 'src/app/models/user';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import * as moment from 'moment';
import { FeatureAccessService } from 'src/app/common-services/feature-access.service';
import { getUserLocationsQueryString } from 'src/app/utils/user-roles/get-location-query-string';
import { addTargetedToAddToTagList } from 'src/app/utils/add-targeted-to-add-to-target-list/add-targeted-to-add-to-target-list';
import { addStatusToAddToTagList } from 'src/app/utils/add-status-to-add-to-target-list/add-status-to-add-to-target-list';
import { createTagList } from 'src/app/utils/create-tag-list/create-tag-list';
import { SurveysService } from '../surveys.service';
import { OnceOffTabType } from './once-off-tab-type.enum';

interface IOnceOffTab {
  name: string;
  type: OnceOffTabType;

  index: number;
}

@Component({
  selector: 'app-once-off-surveys',
  templateUrl: './once-off-surveys.component.html',
  styleUrls: ['./once-off-surveys.component.scss']
})
export class OnceOffSurveysComponent implements OnInit {
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  user: User = {};

  filter: string = '';

  hasNext: string;

  index: number = 0;

  pageIndex: number = 0;

  pageSize: number = 10;

  surveyGroups: IOnceOffTab[] = [];

  defaultTab: OnceOffTabType = OnceOffTabType.ActiveForAdmin;

  surveysToShow: any[] = [];

  tableColumns: string[];

  totalCount: number = 0;

  isLoading: boolean = false;

  faPlus = faPlus;

  faCheckSquare = faCheckSquare;

  faPlayCircle = faPlayCircle;

  allTemplates: any[] = [];

  date = moment();

  status = '';

  roleType = '';

  public viewTags = false;

  public viewAllActive = false;

  public viewAllUpcoming = false;

  public viewAllClosed = false;

  public viewAll = false;

  public viewAllAssignedAndActive = false;

  public viewAllAssignedAndClosed = false;

  public viewAssignedResponses = false;

  constructor(
    private elementRef: ElementRef,
    private surveyCompletionData: LoaderService,
    private csaAuth: CsaAuthService,
    private router: Router,
    private surveyService: SurveysService,
    private featureAccessService: FeatureAccessService, 
  ) { }

  ngOnInit(): void {
    this.getPermissions();
    this.user = this.csaAuth.user;
    this.formatTabs();
    this.setupTabOutput(this.defaultTab);
    this.getSurveysToShow(true);
  }

  getPermissions() {
    this.viewTags = this.featureAccessService.hasAccess('tags.view');
    this.viewAllActive = this.featureAccessService.hasAccess('onceOffAssessments.viewAllActive');
    this.viewAllUpcoming = this.featureAccessService.hasAccess('onceOffAssessments.viewAllUpcoming');
    this.viewAllClosed = this.featureAccessService.hasAccess('onceOffAssessments.viewAllClosed');
    this.viewAll = this.featureAccessService.hasAccess('onceOffAssessments.viewAll');
    this.viewAllAssignedAndActive = this.featureAccessService.hasAccess('onceOffAssessments.viewAllAssignedAndActive');
    this.viewAllAssignedAndClosed = this.featureAccessService.hasAccess('onceOffAssessments.viewAllAssignedAndClosed');
    this.viewAssignedResponses = this.featureAccessService.hasAccess('onceOffAssessments.viewAssignedResponses');
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent) {
    const selectedTab = this.getTabByIndex(tabChangeEvent.index);
    const selectedTabType = selectedTab ? selectedTab.type : this.defaultTab;
    this.paginator.pageIndex = 0;
    this.pageIndex = 0;
    this.index = 0;
    this.pageSize = this.paginator.pageSize;
    this.setupTabOutput(selectedTabType);
    this.getSurveysToShow();
  }

  getTabByIndex(index: number): IOnceOffTab | undefined {
    return this.surveyGroups.find((tab: IOnceOffTab) => tab.index === index);
  }

  getNext(event: PageEvent) {
    this.index = event.pageIndex * event.pageSize;
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getSurveysToShow();
  }

  formatTabs() {
    this.defaultTab = undefined;
    this.surveyGroups = [];
    let index = -1;
    if (this.viewAllActive) {
      index++;
      this.surveyGroups.push({ name: 'Active', type: OnceOffTabType.ActiveForAdmin, index });
    }
    if (this.viewAllUpcoming) {
      index++;
      this.surveyGroups.push({ name: 'Upcoming', type: OnceOffTabType.UpcomingForAdmin, index });
    }
    if (this.viewAllClosed) {
      index++;
      this.surveyGroups.push({ name: 'Closed', type: OnceOffTabType.ClosedForAdmin, index });
    }
    if (this.viewAll) {
      index++;
      this.surveyGroups.push({ name: 'All', type: OnceOffTabType.AllForAdmin, index });
    }
    if (this.viewAllAssignedAndActive) {
      index++;
      this.surveyGroups.push({ name: 'Active', type: OnceOffTabType.ActiveForAboveStore, index });
    }
    if (this.viewAllAssignedAndClosed) {
      index++;
      this.surveyGroups.push({ name: 'Closed', type: OnceOffTabType.ClosedForAboveStore, index });
    }
    if (this.viewAssignedResponses) {
      index++;
      this.surveyGroups.push({ name: 'Unopened', type: OnceOffTabType.UnOpenedForStore, index });
    }
    if (this.viewAssignedResponses) {
      index++;
      this.surveyGroups.push({ name: 'Started', type: OnceOffTabType.StartedForStore, index });
    }
    if (this.viewAssignedResponses) {
      index++;
      this.surveyGroups.push({ name: 'Submitted', type: OnceOffTabType.SubmittedForStore, index });
    }
    if (this.viewAssignedResponses) {
      index++;
      this.surveyGroups.push({ name: 'Closed', type: OnceOffTabType.ClosedForStore, index });
    }
    if (this.viewAssignedResponses) {
      index++;
      this.surveyGroups.push({ name: 'All', type: OnceOffTabType.AllForStore, index });
    }
    this.defaultTab = this.surveyGroups ? this.surveyGroups[0].type : OnceOffTabType.ActiveForAdmin;
  }

  setupTabOutput(type: OnceOffTabType) {
    switch (type) {
      case OnceOffTabType.ActiveForAdmin:
        this.tableColumns = ['tags', 'survey', 'dueDate', 'percentComplete', 'actions'];
        this.status = 'published';
        this.roleType = 'admin';
        break;
      case OnceOffTabType.UpcomingForAdmin:
        this.tableColumns = ['tags', 'survey', 'publishDate', 'templateUsed', 'questions'];
        this.status = 'active';        
        this.roleType = 'admin';
        break;
      case OnceOffTabType.ClosedForAdmin:
        this.tableColumns = ['tags', 'survey', 'dueDate', 'percentComplete', 'actions'];
        this.status = 'closed';        
        this.roleType = 'admin';
        break;
      case OnceOffTabType.AllForAdmin:
        this.tableColumns = ['tags', 'survey', 'publishDate', 'dueDate', 'templateUsed'];
        this.status = '';        
        this.roleType = 'admin';
        break;
      case OnceOffTabType.ActiveForAboveStore:
        this.tableColumns = ['tags', 'survey', 'dueDate', 'percentComplete', 'actions'];
        this.status = 'published';        
        this.roleType = 'aboveStore';
        break;
      case OnceOffTabType.ClosedForAboveStore:
        this.tableColumns = ['tags', 'survey', 'dueDate', 'percentComplete', 'actions'];
        this.status = 'closed';        
        this.roleType = 'aboveStore';
        break;
      case OnceOffTabType.UnOpenedForStore:
        this.tableColumns = ['tags', 'survey', 'percentComplete', 'status'];
        this.status = 'active';        
        this.roleType = 'store';
        break;
      case OnceOffTabType.StartedForStore:
        this.tableColumns = ['tags', 'survey', 'percentComplete', 'status'];
        this.status = 'started';        
        this.roleType = 'store';
        break;
      case OnceOffTabType.SubmittedForStore:
        this.tableColumns = ['tags', 'survey', 'percentComplete', 'status'];
        this.status = 'submitted';        
        this.roleType = 'store';
        break;
      case OnceOffTabType.ClosedForStore:
        this.tableColumns = ['tags', 'survey', 'percentComplete', 'status'];
        this.status = 'closed';        
        this.roleType = 'store';
        break;
      case OnceOffTabType.AllForStore:
        this.tableColumns = ['tags', 'survey', 'percentComplete', 'status'];
        this.status = '';        
        this.roleType = 'store';
        break;
    }
  }

  fetchAdminSurveys() {
    this.surveyService
      .getSurveys(
        '/admin',
        this.user['division'],
        this.status,
        `/${this.index}`,
        `/${this.pageSize}`
      )
      .subscribe((surveysData) => {
        this.surveysToShow = surveysData['surveys'];
        this.hasNext = surveysData['has_next'];
        for (let i = 0; i < this.surveysToShow.length; i++) {
          this.surveysToShow[i].template = '';
          for (let j = 0; j < this.allTemplates.length; j++) {
            if (this.allTemplates[j].id == this.surveysToShow[i].template_id) {
              this.surveysToShow[i].template = this.allTemplates[j].title;
            }
          }
        }
        this.surveysToShow = addTargetedToAddToTagList(this.surveysToShow);
        this.surveysToShow = addStatusToAddToTagList(this.surveysToShow, ['Cancelled']);
        this.surveysToShow = createTagList(this.surveysToShow, this.viewTags, ['addToTagList']);           
        this.getPagination();
        this.isLoading = false;
      }, () => {
        this.isLoading = false;
      });      
  }

  fetchAboveStoreSurveys() {
    this.filter = getUserLocationsQueryString(this.csaAuth.user);
    this.surveyService
      .getSurveys(
        '/above-store',
        `${this.user['division']}${status}${this.filter}`,
        this.status,
        `/${this.index}`,
        `/${this.pageSize}`
      )
      .subscribe((surveysData) => {
        this.surveysToShow = surveysData['surveys'];
        this.surveysToShow = addTargetedToAddToTagList(this.surveysToShow);
        this.surveysToShow = addStatusToAddToTagList(this.surveysToShow, ['Cancelled']);
        this.surveysToShow = createTagList(this.surveysToShow, this.viewTags, ['addToTagList']);        
        this.hasNext = surveysData['has_next'];
        this.getPagination();
        this.isLoading = false;
      }, () => {
        this.isLoading = false;
      });         
  }

  fetchStoreSurveys() {
    this.filter = getUserLocationsQueryString(this.csaAuth.user);
    const status = this.status ? `status=${this.status}` : '';
    this.surveyService
      .getStoreSurveys(
        `?${status}${this.filter}`,
        `/${this.index}`,
        `/${this.pageSize}`
      )
      .subscribe((surveysData) => {
        this.surveysToShow = surveysData['surveys'];
        this.surveysToShow = addTargetedToAddToTagList(this.surveysToShow);
        this.surveysToShow = addStatusToAddToTagList(this.surveysToShow, ['Cancelled']);
        this.surveysToShow = createTagList(this.surveysToShow, this.viewTags, ['addToTagList']);           
        this.hasNext = surveysData['has_next'];
        this.getDueDates(this.surveysToShow);
        this.getSurveyStates(this.surveysToShow);
        this.getPagination();
        this.isLoading = false;
      }, () => {
        this.isLoading = false;
      });          
  }  

  getSurveysToShow(isInitial = false) {
    setTimeout(() => {
      this.isLoading = true;
      this.surveysToShow = [];
    }, 0);

    switch (this.roleType) {
      case 'admin':
        if (isInitial) {
          this.surveyService
            .getTemplates(
              `/list/${this.user['division']}?status=archived&status=active`
            )
            .subscribe((templateData) => {
              this.allTemplates = templateData['templates'];
              this.fetchAdminSurveys();
            });
        } else {
          this.fetchAdminSurveys();
        }     
        break;
      case 'aboveStore':
        this.fetchAboveStoreSurveys();
        break;
      case 'store':
        this.fetchStoreSurveys();
        break;
    }
  }

  getSurveyStates(surveys) {
    for (let i = 0; i < surveys.length; i++) {
      const status = surveys[i].status;
      if (status == 'active') {
        surveys[i].surveyState = 'Start';
      } else if (status == 'started') {
        surveys[i].surveyState = 'Continue';
      } else if (status == 'submitted') {
        surveys[i].surveyState = 'Submitted';
      } else if (status == 'closed') {
        surveys[i].surveyState = 'Closed';
      }
    }
  }

  getDueDates(surveys) {
    for (let i = 0; i < surveys.length; i++) {
      const surveyDueDate = surveys[i].dueDate;
      const m = moment(surveyDueDate, [
        'DD/MM/YYYY',
        'DD MMM YYYY',
        moment.ISO_8601,
      ]);
      const currentDate = moment();
      const isDueInWeek = currentDate.diff(m, 'days') >= -7 && currentDate.diff(m, 'days') <= 0;

      surveys[i].isDueInWeek = isDueInWeek;
    }
  }

  getPagination() {
    const label = this.elementRef.nativeElement.querySelector(
      '.mat-paginator-range-label'
    );
    if (label) {
      if (this.surveysToShow.length == 0) {
        label.innerHTML = 'No results to show';
        this.totalCount = 0;
      } else {
        const upToCount = this.index + this.surveysToShow.length;

        if (this.hasNext == 'Y') {
          label.innerHTML = `${String(this.index + 1)} to ${upToCount} of Many`;
          this.totalCount = upToCount + 1;
        } else {
          label.innerHTML = `${String(this.index + 1)} to ${upToCount} of ${upToCount}`;
          this.totalCount = upToCount;
        }
      }
    }
  }

  startSurvey(surveyID, event) {
    event.stopPropagation();
    if (this.roleType === 'store') {
      this.router.navigate(['/assessment-response-edit', surveyID]);
    }
  }

  surveyDetails(survey: object) {
    switch (this.roleType) {
      case 'admin':
      case 'aboveStore':
        this.surveyCompletionData.changeMessage(survey['avgCompletionRate']);
        this.router.navigate(['/once-off-assessment-overview', survey['id']]);   
        break;
      case 'store':
        this.router.navigate(['/assessment-response-view', survey['id']]);   
        break;
    }
  }
}
