import {
  Component,
  OnInit,
  EventEmitter,
  ChangeDetectorRef,
  KeyValueDiffer,
  KeyValueDiffers,
  NgZone, 
  ViewChild, 
  ElementRef, 
  OnDestroy
} from '@angular/core';
import {
  ActivatedRoute,
  Router,
} from '@angular/router';
import { NgForm } from '@angular/forms';
import * as moment from 'moment/moment';
import { MatDialog } from '@angular/material/dialog';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import {
  faAngleDown,
  faAngleUp,
  faCalendarCheck,
  faCamera,
  faCheck,
  faCircleNotch,
  faExclamation,
  faPaperPlane,
  faPencilAlt,
  faFileInvoice,
  faSave,
  faTrashAlt,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import Compressor from 'compressorjs';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import htmlToPdfmake from 'html-to-pdfmake';
import { isActionMarkAsCompleteCheckboxEnabled } from 'src/app/utils/is-action-mark-as-complete-checkbox-enabled/is-action-mark-as-complete-checkbox-enabled';
import { CsaAuthService } from '../../auth/csa-auth.service';
import { CreateActionDialogComponent } from '../create-action-dialog/create-action-dialog.component';
import { PhotoCarouselComponent } from '../photo-carousel/photo-carousel.component';
import { PhotoPreviewComponent } from '../photo-preview/photo-preview.component';
import { MessagesComponent } from '../messages/messages.component';
import { UnescapePipe } from '../../custom-pipes/unescape.pipe';
import { CsaBackEndService } from '../../common-services/csa-back-end.service';
import { LoaderService } from '../../common-services/loader.service';
import { User } from '../../models/user';
import { SurveysService } from '../surveys.service';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-assessment-response-edit',
  templateUrl: './assessment-response-edit.component.html',
  styleUrls: ['./assessment-response-edit.component.scss'],
  providers: [UnescapePipe],
})
export class AssessmentResponseEditComponent implements OnInit, OnDestroy {
  private differ: KeyValueDiffer<string, any>;

  @ViewChild('surveyForm') form;

  @ViewChild('surveyForm') surveyForm: NgForm;

  @ViewChild('printSurvey') printSurvey: ElementRef;

  faAngleDown = faAngleDown;

  faAngleUp = faAngleUp;

  faCalendarCheck = faCalendarCheck;

  faCamera = faCamera;

  faCheck = faCheck;

  faCircleNotch = faCircleNotch;

  faExclamation = faExclamation;

  faPaperPlane = faPaperPlane;

  faPencilAlt = faPencilAlt;

  faFileInvoice = faFileInvoice;

  faSave = faSave;

  faTrash = faTrashAlt;

  faTimes = faTimes;

  actions = [];

  actionsValid;

  allExpandState = true;

  incompleteActions = 0;

  individualObjDiffer = {};

  invalid = [];

  minToDate;

  newAction = {};

  notesTimer;

  objDiffer = {};

  openItems = [];

  outstandingActions = 0;

  outstandingQuestions = 0;

  printVersion: string = '';

  saved = false;

  saving = false;

  showNote = {};

  surveyDetails = {};

  surveyID = '';

  surveyResponses = {};

  surveys = [];

  surveyStates = [];

  user: User;

  constructor(
    private differs: KeyValueDiffers,
    private zone: NgZone,
    private unescapePipe: UnescapePipe,
    private cd: ChangeDetectorRef,
    private csaAuth: CsaAuthService,
    private loader: LoaderService,
    private csaBackEnd: CsaBackEndService,
    private actRoute: ActivatedRoute,
    public dialog: MatDialog,
    private router: Router,
    private surveyService :SurveysService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }

  ngOnInit() {
    this.surveyID = this.actRoute.snapshot.paramMap.get('id');
    this.user = this.csaAuth.user;
    this.surveyService.getSurveyById(this.surveyID).subscribe((surveyData) => {
      if (
        surveyData['status'] == 'submitted'
        || surveyData['status'] == 'closed'
      ) {
        this.router.navigate([
          '/assessment-response-view',
          surveyData['id'],
        ]);
      }

      this.differ = this.differs.find(this.surveyDetails).create();
      this.surveyDetails = surveyData;
      this.surveyDetails['responses'].forEach((response) => {
        response = {};
      });
      this.getNotes(surveyData);
      this.getFormValues();
      this.getEditActionState();
      const changes = this.differ.diff(this.surveyDetails);
      if (changes) {
        changes.forEachChangedItem((r) => {});
        changes.forEachAddedItem((r) => {});
        changes.forEachRemovedItem((r) => {});
      }
    });

    window.addEventListener('scroll', this.scroll, true);
    this.cd.detectChanges();
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.scroll, true);
  }

  @ViewChild('saveButton', { static: false }) saveButton: ElementRef;

  scroll = (event): void => {
    if (event.srcElement.scrollTop > 75) {
      this.saveButton['nativeElement'].classList.add('float-right');
    } else {
      this.saveButton['nativeElement'].classList.remove('float-right');
    }
  };

  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  printResponse(value) {
    let showPrint = true;
    switch (value) {
      case 'Answered':
        if (!this.surveyDetails['responses'].find(
          (({ response }) => response !== '')
        )) {
          showPrint = false;
        }
        this.printVersion = 'Answered Questions';
        break;
      case 'Unanswered':
        if (!this.surveyDetails['responses'].find(
          (({ response }) => response === '')
        )) {
          showPrint = false;
        }
        this.printVersion = 'Unanswered Questions';
        break;
      case 'Yes':
      case 'No':
        if (!this.surveyDetails['responses'].find(
          (({ response }) => response === value)
        )) {
          showPrint = false;
        }
        this.printVersion = `Questions Answered '${value}'`;
        break;
      default:
        this.printVersion = 'All Questions';
        break;
    }

    if (!showPrint) {
      this.dialog.open(MessagesComponent, {
        data: {
          heading: 'Cannot Display',
          message: `<i>${this.printVersion}</i> has no matches. Please select a different filter option.`
        },
        disableClose: true,
        backdropClass: 'dialog-backdrop',
      });
    } else {
      setTimeout(() => {
        this.htmlToPDF();
      }, 500);
    }
  }

  htmlToPDF() {
    const html = htmlToPdfmake(this.printSurvey.nativeElement.innerHTML);
    const documentDefinition = { 
      content: [html], 
      styles: {
        active: {
          bold: true,
        },
        'actions-heading': {
          paddingTop: 10,
          marginBottom: 0,
          textAlign: 'left'
        },
        'no-top-margin': {
          marginTop: 0
        },
        'action-line': {
          display: 'inline'
        },
        'todo-text': {
          whiteSpace: 'pre-wrap',
          fontStyle: { italics: true }
        }
      }
    };
    pdfMake.createPdf(documentDefinition).open();  
  }

  setOpened(itemIndex) {
    this.openItems.push(itemIndex);
  }

  setClosed(itemIndex) {
    for (let i = 0; i < this.openItems.length; i++) {
      if (this.openItems[i] === itemIndex) {
        this.openItems.splice(i, 1);
      }
    }
  }

  viewPhoto(photoName, question, fileName) {
    let photo = '';

    if (fileName) {
      photo = fileName;
    } else {
      photo = `${this.surveyDetails['id']
      }-${
        question
      }-${
        this.surveyDetails['storeID']
      }-${
        photoName}`;
    }

    const query = `?fileNames=${photo}`;
    this.loader.show('');

    this.surveyService.getPhotos(query).subscribe(
      (photoData) => {
        if (Object.keys(photoData).length == 0) {
          this.loader.hide('');
          const messageDialog = this.dialog.open(MessagesComponent, {
            data: {
              heading: 'File cannot be viewed, please retry',
              message:
              `Please email ${this.user['supportEmail']} for support`,
              closeText: 'Ok',
              retryText: 'Retry'
            },
            backdropClass: 'dialog-backdrop',
          });
          messageDialog.afterClosed().subscribe((result) => {
            if (result == 'retry') {
              this.viewPhoto(photoName, question, fileName);
            }
          });
        } else if (Object.keys(photoData).length > 0) {
          this.zone.run(() => {
            const photoDialog = this.dialog.open(PhotoPreviewComponent, {
              data: {
                photoURL: photoData[photo],
                photoName,
                showRemove: true,
                documentType: 'Evidence'
              },
              backdropClass: 'dialog-backdrop',
              minWidth: '70%',
              maxWidth: '90vw',
              height: 'auto',
            });
            photoDialog.afterClosed().subscribe((result) => {
              if (result == 'delete') {
                this.removePhoto(photoData, question, photo);
              }
            });
          });
        }
      },
      (error) => {
        this.loader.hide('');
        const messageDialog = this.dialog.open(MessagesComponent, {
          data: {
            heading: 'File cannot be viewed, please retry',
            message:
              `Please email ${this.user['supportEmail']} for support`,
            closeText: 'Ok',
            retryText: 'Retry'
          },
          backdropClass: 'dialog-backdrop',
        });
        messageDialog.afterClosed().subscribe((result) => {
          if (result == 'retry') {
            this.viewPhoto(photoName, question, fileName);
          }
        });
      }
    );
  }

  getAllPhotos(questionID: string, fileName?: string, photoIndex = 0) {
    const evidence = [];
    let questionIndex = 0;

    for (let i = 0; i < this.surveyDetails['responses'].length; i++) {      
      const response = this.surveyDetails['responses'][i];
      const question = this.surveyDetails['questions'][i];
      const photos = question['photos'];
      if (photos && photos.length > 0) {
        const responseEvidence = {
          photos: [],
          questionTitle: question['title'],
          questionID: question['id'],
          sectionHeading: question['sectionHeading'],
          totalIndex: i + 1,
          response: response['response']
        };

        if (questionID == question['id']) {
          questionIndex = evidence.length;
        }

        for (let j = 0; j < photos.length; j++) {
          if (!photos[j]['fileName']) {
            photos[j]['fileName'] = `${this.surveyDetails['id'] 
            }-${question['id'] 
            }-${this.user['storeID'] 
            }-${photos[j]['photoName']}`;
          }

          if (fileName == photos[j]['fileName'].replace(/\s/g, '-').replace(/\.[^.]*$/, '')) {
            photoIndex = j;
          }

          responseEvidence.photos.push({
            photoName: photos[j]['photoName'],
            fileName: photos[j]['fileName'],
          });
        }

        evidence.push(responseEvidence);
      }
    }
  
    const photoDialog = this.dialog.open(PhotoCarouselComponent, {
      data: {
        evidence,
        evidenceIndex: questionIndex,
        photoIndex,
        totalNumber: this.surveyDetails['questions'].length,
        showRemove: true,
        documentType: 'Evidence',
        type: 'survey'
      },
      backdropClass: 'dialog-backdrop',
      minWidth: '70%',
      maxWidth: '90vw',
      height: 'auto'
    });
    photoDialog.afterClosed().subscribe((result) => {
      if (result.status == 'delete') {
        console.log(result);
        this.removePhoto(result.src, result.questionID, result.fileName);
      }
    });
  }

  removePhoto(src, question, fileName) {
    this.surveyService
      .removePhoto(fileName, src, this.surveyDetails['id'], question)
      .subscribe(
        (response) => {
          this.removePhotoFromQ(question, fileName);
          this.cd.detectChanges();
          // this.saveForm(question);
        },
        (error) => {
          this.dialog.open(MessagesComponent, {
            data: {
              heading: "Couldn't Remove File",
              message:
                `Please email ${this.user['supportEmail']} for support`,
              closeText: 'Ok',
            },
            backdropClass: 'dialog-backdrop',
          });
        }
      );
  }

  removePhotoFromQ(question, fileName) {
    for (let i = 0; i < this.surveyDetails['questions'].length; i++) {
      if (this.surveyDetails['questions'][i]['id'] == question) {
        for (
          let j = 0;
          j < this.surveyDetails['questions'][i]['photos'].length;
          j++
        ) {
          if (
            this.surveyDetails['questions'][i]['photos'][j]['photoName']
            == fileName
          ) {
            this.surveyDetails['questions'][i]['photos'].splice(j, 1);
            setTimeout(() => {
              this.surveyDetails['questions'][i]['uploading'] = false;
            }, 10);
          } else if (
            this.surveyDetails['questions'][i]['photos'][j]['fileName']
            == fileName
          ) {
            this.surveyDetails['questions'][i]['photos'].splice(j, 1);
            setTimeout(() => {
              this.surveyDetails['questions'][i]['uploading'] = false;
            }, 10);
          }
        }
      }
    }
  }

  compressPhoto(file, question) {
    const emitter = new EventEmitter<Blob>();

    const compression = new Compressor(file, {
      maxWidth: 1000,
      maxHeight: 1000,
      convertSize: 1000000,
      success(result) {
        emitter.emit(result);
      },
      error(err) {
        emitter.emit();
      },
    });
    return emitter.asObservable();
  }

  checkName(name) {
    const check = name.toLowerCase().match(/\.(pdf)/g);
    if (check == null) {
      return true;
    } 
    return false;
  }

  uploadPhoto($event, questionID, survey) {
    const questionIndex = this.surveyDetails['questions'].findIndex((item) => item['id'] === questionID);
    this.surveyDetails['questions'][questionIndex]['uploading'] = true;
    const photoName = $event.target.files[0].name;
    const goodPhotoType = $event.target.files[0].name
      .toLowerCase()
      .match(/\.(pdf|jpg|png|gif|tiff|jpeg|bmp|raw)/g);

    const PDFTest = $event.target.files[0].name.toLowerCase().match(/\.(pdf)/g);
    if (goodPhotoType == null) {
      this.displayUploadError(
        'This file is not an image or PDF',
        'Please select an image or PDF file to attach.',
        questionIndex
      );
    } else if (PDFTest == null) {
      this.compressPhoto($event.target.files[0], questionID).subscribe(
        (compressedPhoto) => {
          if (typeof compressedPhoto === 'undefined') {
            this.displayUploadError(
              'Error Compressing File',
              `Please try a different file or email ${ 
                this.user['supportEmail']
              } for support`,
              questionIndex
            );
          } else if (compressedPhoto['size'] > 10000000) {
            this.displayUploadError(
              'This File Is Too Big',
              'Please upload a smaller file',
              questionIndex
            );
          } else {
            const action = this.surveyService
              .uploadPhoto(
                compressedPhoto,
                survey,
                questionID,
                this.surveyDetails['storeID'],
                `${compressedPhoto['size']}`
              )
              .subscribe(
                (response) => {
                  if (!response[0] || response[0]['status'] != 200) {
                    let message;
                    if (response[0]['status'] != 200) {
                      message = `There was an error when sending your file. Please email ${ 
                        this.user['supportEmail']
                      } for support`;
                    } else {
                      message = `No response from server. Please email ${ 
                        this.user['supportEmail']
                      } for support`;
                    }
                    this.displayUploadError(
                      "Couldn't Add File",
                      message,
                      questionIndex
                    );
                  } else if (response[0]['message'] == 'OK') {
                    this.getPhotosThisQuestion(questionIndex, {
                      photoName: response[0]['name'],
                      fileName: response[0]['fileID'],
                      fileSize: compressedPhoto['size'],
                    });
                  } 
                },
                (error) => {
                  this.displayUploadError(
                    "Couldn't Add File",
                    `Please email ${ 
                      this.user['supportEmail']
                    } for support`,
                    questionIndex
                  );
                }
              );
          }
        }
      );
    } else if ($event.target.files[0]['size'] > 10000000) {
      this.dialog.open(MessagesComponent, {
        data: {
          heading: 'This File Is Too Big',
          message: 'Please upload a smaller file',
          closeText: 'Ok',
        },
        backdropClass: 'dialog-backdrop',
      });
      setTimeout(() => {
        this.surveyDetails['questions'][questionIndex]['uploading'] = false;
      }, 10);
    } else {
      const action = this.surveyService
        .uploadPhoto(
          $event.target.files[0],
          survey,
          questionID,
          this.surveyDetails['storeID'],
          $event.target.files[0]['size']
        )
        .subscribe(
          (response) => {
            if (!response[0] || response[0]['status'] != 200) {
              let message;
              if (response[0]['status'] != 200) {
                message = `There was an error when sending your file. Please email ${ 
                  this.user['supportEmail']
                } for support`;
              } else {
                message = `No response from server. Please email ${ 
                  this.user['supportEmail']
                } for support`;
              }
              this.displayUploadError("Couldn't Add File", message, questionIndex);
            } else {
              this.getPhotosThisQuestion(questionIndex, {
                photoName: response[0]['name'],
                fileName: response[0]['fileID'],
                fileSize: $event.target.files[0]['size'],
              });
              this.cd.detectChanges();
            }
          },
          (error) => {
            this.displayUploadError(
              "Couldn't Add File",
              `Please email ${
                this.user['supportEmail']
              } for support`,
              questionIndex
            );
          }
        );
    }
  }

  displayUploadError(heading, message, questionIndex) {
    this.dialog.open(MessagesComponent, {
      data: {
        heading,
        message,
        closeText: 'Ok',
      },
      backdropClass: 'dialog-backdrop',
    });
    setTimeout(() => {
      this.surveyDetails['questions'][questionIndex]['uploading'] = false;
    }, 10);
  }

  getPhotosThisQuestion(questionIndex, photo) {
    // this.saveForm(this.surveyDetails['questions'][questionIndex]['id']);
    setTimeout(() => {
      if (!this.surveyDetails['questions'][questionIndex]['photos']) {
        this.surveyDetails['questions'][questionIndex]['photos'] = [];
        // this.surveyDetails["responses"][questionIndex]["photos"] = [];
      }
      this.surveyDetails['questions'][questionIndex]['photos'].push(photo);
      // this.surveyDetails["responses"][questionIndex]["photos"].push(photo);
      this.surveyDetails['questions'][questionIndex]['uploading'] = false;
    }, 10);
    this.cd.detectChanges();
  }

  getSurveyResponses(question) {
    for (let i = 0; i < this.surveyDetails['responses'].length; i++) {
      if (this.surveyDetails['responses'][i]['question_id'] == question) {
        return this.surveyDetails['responses'][i]['response'];
      }
    }
  }

  unescapeHtml(text) {
    return text
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace(/&#34;/g, '"')
      .replace(/&#39;/g, "'")
      .replace(/&amp;/g, '&');
  }

  getNotes(surveyData) {
    if (!this.surveyDetails['sectionHeading']) {
      this.surveyDetails['sectionHeading'] = ['All Questions'];
      this.surveyDetails['questions'].forEach((question) => {
        question['sectionHeading'] = 'All Questions';
      });
    }

    for (let i = 0; i < this.surveyDetails['questions'].length; i++) {
      for (let j = 0; j < this.surveyDetails['responses'].length; j++) {
        this.objDiffer[this.surveyDetails['responses'][j]['question_id']] = this.differs.find(this.surveyDetails['responses'][j]).create();
        this.individualObjDiffer[
          this.surveyDetails['responses'][j]['question_id']
        ] = this.differs.find(this.surveyDetails['responses'][j]).create();
        this.surveyDetails['responses'][j] = surveyData['responses'][j];
        if (
          this.surveyDetails['responses'][j]['question_id']
          == this.surveyDetails['questions'][i]['id']
        ) {
          if (this.surveyDetails['responses'][j]['notes']) {
            this.surveyDetails['questions'][i]['showNotes'] = true;
            this.surveyDetails['responses'][j]['notes'] = this.unescapeHtml(
              this.surveyDetails['responses'][j]['notes']
            );
            this.surveyDetails['questions'][i]['notes'] = this.surveyDetails['responses'][j]['notes'];
          }
          this.surveyDetails['questions'][i]['response'] = this.surveyDetails['responses'][j]['response'];
          this.surveyDetails['questions'][i]['actions'] = this.surveyDetails['responses'][j]['actions'];
          this.surveyDetails['questions'][i]['photos'] = this.surveyDetails['responses'][j]['photos'];
          this.surveyDetails['questions'][i]['pointInTimeReviewDate'] = this.surveyDetails['responses'][i]['pointInTimeReviewDate'];
          this.surveyDetails['questions'][i]['reviewedFrom'] = this.surveyDetails['responses'][i]['reviewedFrom'];
          this.surveyDetails['questions'][i]['reviewedTo'] = this.surveyDetails['responses'][i]['reviewedTo'];
          this.minToDate = moment(
            this.surveyDetails['questions'][i]['reviewedFrom'],
            ['DD/MM/YYYY', 'DD MMM YYYY', moment.ISO_8601]
          );

          if (!this.surveyDetails['responses'][i]['autoSavedAction']) {
            this.surveyDetails['responses'][i]['autoSavedAction'] = [];
            this.surveyDetails['questions'][i]['autoSavedAction'] = [];
          } else {
            this.surveyDetails['questions'][i]['autoSavedAction'] = this.surveyDetails['responses'][i]['autoSavedAction'];
          }

          if (!this.surveyDetails['questions'][i]['sectionHeading']) {
            this.surveyDetails['responses'][j]['sectionHeading'] = 'All Questions';
            this.surveyDetails['questions'][i]['sectionHeading'] = 'All Questions';
          } else {
            this.surveyDetails['responses'][j]['sectionHeading'] = this.surveyDetails['questions'][i]['sectionHeading'];
          }
        }
        const responseDiffer = this.objDiffer[this.surveyDetails['responses'][j]['question_id']];
        const objChanges = responseDiffer.diff(
          this.surveyDetails['responses'][j]
        );
        if (objChanges) {
          objChanges.forEachChangedItem((r) => {});
          objChanges.forEachAddedItem((r) => {});
          objChanges.forEachRemovedItem((r) => {});
        }

        const indResponseDiffer = this.objDiffer[this.surveyDetails['responses'][j]['question_id']];
        const IndObjChanges = indResponseDiffer.diff(
          this.surveyDetails['responses'][j]
        );
        if (IndObjChanges) {
          IndObjChanges.forEachChangedItem((r) => {});
          IndObjChanges.forEachAddedItem((r) => {});
          IndObjChanges.forEachRemovedItem((r) => {});
        }
      }
      setTimeout(() => {
        this.surveyDetails['questions'][i]['uploading'] = false;
      }, 10);
    }
  }

  checkActionsValid(surveyValues) {
    this.outstandingQuestions = 0;
    this.outstandingActions = 0;
    this.incompleteActions = 0;
    this.actionsValid = true;
    if (this.surveyDetails['questions']) {
      for (let i = 0; i < this.surveyDetails['questions'].length; i++) {
        const responseID = `${this.surveyDetails['questions'][i].id}response`;

        if (!surveyValues[responseID]) {
          this.outstandingQuestions++;
          this.cd.detectChanges();
        }

        if (
          this.surveyDetails['questions'][i]['type'] != 'freeText'
          && !this.surveyDetails['questions'][i]['radioOptions']
          && surveyValues[responseID] == 'No'
          && !this.surveyDetails['responses'][i]['actions']
          && !this.surveyDetails['questions'][i]['actions']
        ) {
          this.outstandingActions++;
          this.actionsValid = false;
          this.cd.detectChanges();
        } else if (
          this.surveyDetails['questions'][i]['radioOptions'] != undefined
          && this.surveyDetails['questions'][i]['radioOptions'].length > 0
        ) {
          for (
            let j = 0;
            j < this.surveyDetails['questions'][i]['radioOptions'].length;
            j++
          ) {
            if (
              surveyValues[responseID]
                == this.surveyDetails['questions'][i]['radioOptions'][j][
                  'answer'
                ]
              && this.surveyDetails['questions'][i]['radioOptions'][j][
                'enforceAction'
              ] == true
              && !this.surveyDetails['responses'][i]['actions']
              && !this.surveyDetails['questions'][i]['actions']
            ) {
              this.outstandingActions++;
              this.actionsValid = false;
              this.cd.detectChanges();
            }
          }
        }

        if (this.surveyDetails['questions'][i]['autoSavedAction'].length > 0) {
          setTimeout(
            () => (this.incompleteActions
                += this.surveyDetails['questions'][i]['autoSavedAction'].length)
          );
          setTimeout(() => (this.actionsValid = false));
        }
      }
    }
    
    setTimeout(() => this.saving = false);

    this.findInvalidControls();
  }

  makeNoteShow(question) {
    for (let i = 0; i < this.surveyDetails['questions'].length; i++) {
      if (this.surveyDetails['questions'][i]['id'] == question) {
        if (this.surveyDetails['questions'][i]['showNotes'] == true) {
          this.surveyDetails['questions'][i]['showNotes'] = false;
        } else {
          this.surveyDetails['questions'][i]['showNotes'] = true;
        }
      }
    }
    this.cd.detectChanges();
  }

  ngAfterViewInit() {
    document.querySelector('.mat-drawer-content').scrollTop = 0;
    setTimeout(() => {
      this.form.control.valueChanges.subscribe((values) => this.autoSave(values));
    });

    const invalidControls = [];
    Object.keys(this.surveyForm['controls']).forEach((control) => {});
  }

  getFormValues() {
    const answers = {};
    for (let i = 0; i < this.surveyDetails['responses'].length; i++) {
      answers[`${this.surveyDetails['responses'][i]['question_id']}response`] = this.surveyDetails['responses'][i]['response'];
    }
    setTimeout(() => {
      this.surveyForm.form.patchValue(answers);
      this.checkActionsValid(this.surveyForm.form.value);
    });
  }

  getEditActionState() {
    for (let i = 0; i < this.surveyDetails['questions'].length; i++) {
      for (let j = 0; j < this.surveyDetails['questions'][i]['actions']?.length; j++) {
        const action = this.surveyDetails['questions'][i]['actions'][j];
        action['checkEnabled'] = isActionMarkAsCompleteCheckboxEnabled(action);
      }
    }    
  }

  autoSave(values) {
    if (this.form.control.pristine == false) {
      this.saved = false;
    }
  }

  createAction(
    survey,
    surveyId,
    question,
    questionId,
    questionShortTitle,
    autoSavedActionID
  ) {
    const dialogRef = this.dialog.open(CreateActionDialogComponent, {
      data: {
        survey,
        question,
        surveyId,
        questionId,
        questionShortTitle,
        autoSavedActionID,
      },
      disableClose: true,
      backdropClass: 'dialog-backdrop',
      minWidth: '70%',
      minHeight: 'calc(100vh - 90px)',
      maxWidth: '90vw',
      height: 'auto',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result['type'] == 'final') {
        this.newAction = result['action'];
        this.getActionsThisQuestion(questionId, this.newAction);
      } else if (result['type'] == 'auto') {
        this.updateAutoSavedAction(questionId, result['action']);
      } else if (result['type'] == 'delete') {
        setTimeout(() => { this.deleteAutoSavedAction(questionId, result['autoSavedActionID']); });
      }

      setTimeout(() => {
        this.checkActionsValid(this.surveyForm.form.value);
      });
    });
  }

  deleteAutoSavedAction(question, actionID) {
    if (actionID) {
      setTimeout(() => {
        for (let i = 0; i < this.surveyDetails['questions'].length; i++) {
          if (
            this.surveyDetails['questions'][i]['id'].split('_').pop() == question
          ) {
            const questionData = this.surveyDetails['questions'][i][
              'autoSavedAction'
            ].filter((e) => e['autoSavedActionID'] != actionID);
  
            const responseData = this.surveyDetails['responses'][i][
              'autoSavedAction'
            ].filter((e) => e['autoSavedActionID'] != actionID);
  
            this.surveyDetails['questions'][i]['autoSavedAction'] = questionData;
            
            this.incompleteActions = this.surveyDetails['questions'][i]['autoSavedAction'].length;
            //
            // this.saveForm(this.surveyDetails['questions'][i]['id']);
          }
        }
        this.checkActionsValid(this.surveyForm.form.value);
      });
    }
  }

  deleteAction(question, actionID) {
    const message = this.dialog.open(MessagesComponent, {
      data: {
        heading: 'Are You Sure?',
        message:
          'Are you sure you want to delete this Action? This cannot be un-done.',
        closeText: 'Delete',
        cancelText: 'Cancel',
        maxWidth: '90vw',
      },
      backdropClass: 'dialog-backdrop',
    });

    message.afterClosed().subscribe((result) => {
      if (result == 'logout') {
        const data = {};
        data['id'] = this.surveyDetails['id'];
        data['question_id'] = `${this.surveyDetails['survey_id']}_${question}`;
        if (actionID) {
          data['autoSavedActionID'] = actionID;
         
          const requestJSONPayload = this.csaBackEnd.attachFirewallBypassPrefixes(data, ['autoSavedAction']);
          this.surveyService.autoSaveAction(requestJSONPayload, 'delete').subscribe(      
            (response) => {
              if (response['status'] == 400 || response['status'] == 500) {
                this.dialog.open(MessagesComponent, {
                  data: {
                    heading: "Couldn't Delete Action",
                    message:
                      `Please email ${ 
                        this.user['supportEmail']
                      } for support`,
                    closeText: 'Ok',
                  },
                  backdropClass: 'dialog-backdrop',
                });
              } else {
                setTimeout(() => { this.deleteAutoSavedAction(question, actionID); });
              }
            },
            (error) => {
              console.log(error);
              
              this.dialog.open(MessagesComponent, {
                data: {
                  heading: "Couldn't Delete Action",
                  message:
                    `Please email ${ 
                      this.user['supportEmail']
                    } for support`,
                  closeText: 'Ok',
                },
                backdropClass: 'dialog-backdrop',
              });
            }
          );
        }
      }
    });
  }

  updateAutoSavedAction(questionID, action) {
    for (let i = 0; i < this.surveyDetails['questions'].length; i++) {
      if (
        this.surveyDetails['questions'][i]['id'].split('_').pop() == questionID
      ) {
        if (action['autoSavedActionID']) {
          let actionExists = false;
          for (
            let j = 0;
            j < this.surveyDetails['questions'][i]['autoSavedAction'].length;
            j++
          ) {
            if (
              action['autoSavedActionID']
              == this.surveyDetails['questions'][i]['autoSavedAction'][j][
                'autoSavedActionID'
              ]
            ) {
              this.surveyDetails['questions'][i]['autoSavedAction'][j] = action;
              actionExists = true;
              break;
            }
          }
          if (actionExists == false) {
            this.surveyDetails['questions'][i]['autoSavedAction'].push(action);
          }
        }
      }
    }
    // this.saveForm('All');
  }

  getActionsThisQuestion(questionID, action) {
    for (let i = 0; i < this.surveyDetails['questions'].length; i++) {
      if (
        this.surveyDetails['questions'][i]['id'].split('_').pop() == questionID
      ) {
        if (!this.surveyDetails['questions'][i]['actions']) {
          this.surveyDetails['questions'][i]['actions'] = [];
        }
        action['title'] = this.unescapePipe.transform(action['title']);
        this.surveyDetails['questions'][i]['actions'].push(action);

        const questionData = this.surveyDetails['questions'][i][
          'autoSavedAction'
        ].filter((e) => e['autoSavedActionID'] != action['autoSavedActionID']);

        const responseData = this.surveyDetails['responses'][i][
          'autoSavedAction'
        ].filter((e) => e['autoSavedActionID'] != action['autoSavedActionID']);

        this.surveyDetails['questions'][i]['autoSavedAction'] = questionData;
        this.surveyDetails['responses'][i]['autoSavedAction'] = responseData;

        // this.saveForm(this.surveyDetails['questions'][i]['id']);
      }
    }
  }

  cancelResponse() {
    const dialogRef = this.dialog.open(MessagesComponent, {
      data: {
        heading: 'Are You Sure?',
        message:
          'If you cancel this response, all answers will be lost. Do you wish to proceed?',
        closeText: 'Yes',
        cancelText: 'No',
      },
      backdropClass: 'dialog-backdrop',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == 'logout') {
        const data = {};
        data['id'] = this.surveyDetails['id'];
        data['status'] = 'cancelled';
        data['modifiedBy'] = this.user['email'];
        // ................. eitb filter name added...................
        if (this.surveyDetails['filterName']) {
          data['filterName'] = this.surveyDetails['filterName'];
        }

        const requestJSONPayload = this.csaBackEnd.attachFirewallBypassPrefixes(data, ['responses']);

        this.surveyService.saveSurvey(requestJSONPayload).subscribe(
          (response) => {
            if (response['status'] == 200) {
              this.router.navigate([
                'onthefly-survey-details',
                this.surveyDetails['survey_id'],
              ]);
            } else {
              this.dialog.open(MessagesComponent, {
                data: {
                  heading: "Couldn't Submit Assessment",
                  message:
                    `Please email ${ 
                      this.user['supportEmail']
                    } for support`,
                  closeText: 'Ok',
                },
                backdropClass: 'dialog-backdrop',
              });
            }
          },
          (error) => {
            console.log(error);
            this.dialog.open(MessagesComponent, {
              data: {
                heading: 'Network Error',
                message:
                  `Please check that you have Wifi or 4g signal, and email ${ 
                    this.user['supportEmail']
                  } for support if the issue persists`,
                closeText: 'Ok',
              },
              backdropClass: 'dialog-backdrop',
            });
          }
        );
      }
    });
  }

  submitForm(value) {
    const dialogRef = this.dialog.open(MessagesComponent, {
      data: {
        heading: 'Are You Sure?',
        message:
          'Submitting will close this Assessment and you will no longer be able to edit your responses. Do you wish to proceed?',
        closeText: 'Submit',
        cancelText: 'Cancel',
      },
      backdropClass: 'dialog-backdrop',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == 'logout') {
        const data = {};
        data['id'] = this.surveyDetails['id'];
        data['status'] = 'submitted';
        data['modifiedBy'] = this.user['email'];
        // ................. eitb filter name added...................
        if (this.surveyDetails['filterName']) {
          data['filterName'] = this.surveyDetails['filterName'];
        }

        let answeredQs = 0;
        const obj = value;
        Object.keys(obj).map((key) => {
          if (key.indexOf('response') !== -1) {
            if (obj[key] != '') {
              answeredQs++;
            }
          }
        });

        if (answeredQs != 0) {
          data['completion'] = answeredQs / this.surveyDetails['questions'].length;
          this.surveyDetails['completion'] = answeredQs / this.surveyDetails['questions'].length;
        }
        data['responses'] = [];

        for (let i = 0; i < this.surveyDetails['questions'].length; i++) {
          const response = {};

          if (this.surveyDetails['questions'][i]['shortTitle']) {
            response['shortTitle'] = this.surveyDetails['questions'][i]['shortTitle'];
            this.surveyDetails['responses'][i]['shortTitle'] = this.surveyDetails['questions'][i]['shortTitle'];
          }
          this.surveyDetails['responses'][i]['response'] = value[`${this.surveyDetails['questions'][i]['id']}response`];
          this.surveyDetails['responses'][i]['notes'] = value[`${this.surveyDetails['questions'][i]['id']}note`];
          this.surveyDetails['responses'][i]['actions'] = this.surveyDetails['questions'][i]['actions'];
          this.surveyDetails['questions'][i]['pointInTimeReviewDate'] = this.surveyDetails['responses'][i]['pointInTimeReviewDate'];

          if (
            value[`${this.surveyDetails['questions'][i]['id']}reviewedFrom`]
          ) {
            response['reviewedFrom'] = moment(
              value[`${this.surveyDetails['questions'][i]['id']}reviewedFrom`],
              ['DD/MM/YYYY', 'DD MMM YYYY', moment.ISO_8601]
            ).format();
            this.surveyDetails['responses'][i]['reviewedFrom'] = moment(
              value[`${this.surveyDetails['questions'][i]['id']}reviewedFrom`],
              ['DD/MM/YYYY', 'DD MMM YYYY', moment.ISO_8601]
            ).format();
          }

          if (value[`${this.surveyDetails['questions'][i]['id']}reviewedTo`]) {
            response['reviewedTo'] = moment(
              value[`${this.surveyDetails['questions'][i]['id']}reviewedTo`],
              ['DD/MM/YYYY', 'DD MMM YYYY', moment.ISO_8601]
            ).format();
            this.surveyDetails['responses'][i]['reviewedTo'] = moment(
              value[`${this.surveyDetails['questions'][i]['id']}reviewedTo`],
              ['DD/MM/YYYY', 'DD MMM YYYY', moment.ISO_8601]
            ).format();
          }

          response['question_id'] = this.surveyDetails['questions'][i]['id'];
          response['response'] = value[`${this.surveyDetails['questions'][i]['id']}response`];
          response['notes'] = value[`${this.surveyDetails['questions'][i]['id']}note`];
          if (this.surveyDetails['questions'][i]['actions']) {
            for (
              let j = 0;
              j < this.surveyDetails['questions'][i]['actions'].length;
              j++
            ) {
              this.surveyDetails['questions'][i]['actions'][j]['title'] = this.unescapePipe.transform(
                this.surveyDetails['questions'][i]['actions'][j]['title']
              );
            }
          }
          response['actions'] = this.surveyDetails['questions'][i]['actions'];
          response['photos'] = this.surveyDetails['questions'][i]['photos'];
          response['pointInTimeReviewDate'] = this.surveyDetails['responses'][i]['pointInTimeReviewDate'];

          data['responses'].push(response);
        }

        const requestJSONPayload = this.csaBackEnd.attachFirewallBypassPrefixes(data, ['responses']);
        
        this.surveyService.saveSurvey(requestJSONPayload).subscribe(
          (response) => {
            if (response['status'] == 200) {
              // Area to place scoring and email trigger logic
              if (this.surveyDetails['filterName'] == 'eitb') {
                const eitbData = {};
                eitbData['id'] = data['id'];
                eitbData['domain'] = this.user['domain'];
                this.surveyService.eitbEmailTrigger(eitbData).subscribe(
                  (eitbResponse) => {
                    this.router.navigate(['thankyou']);
                  },
                  (error) => {
                    console.log(error);
                    this.router.navigate(['thankyou']);
                    this.dialog.open(MessagesComponent, {
                      data: {
                        heading: "Couldn't Calculate GPS Scores",
                        message:
                          `Please email ${ 
                            this.user['supportEmail']
                          } for support`,
                        closeText: 'Ok',
                      },
                      backdropClass: 'dialog-backdrop',
                    });
                  }
                );
              } else {
                this.router.navigate(['thankyou']);
              }
            } else {
              this.dialog.open(MessagesComponent, {
                data: {
                  heading: "Couldn't Submit Assessment",
                  message:
                    `Please email ${ 
                      this.user['supportEmail']
                    } for support`,
                  closeText: 'Ok',
                },
                backdropClass: 'dialog-backdrop',
              });
            }
          },
          (error) => {
            console.log(error);
            this.dialog.open(MessagesComponent, {
              data: {
                heading: 'Network Error',
                message:
                  `Please check that you have Wifi or 4g signal, and email ${ 
                    this.user['supportEmail']
                  } for support if the issue persists`,
                closeText: 'Ok',
              },
              backdropClass: 'dialog-backdrop',
            });
          }
        );
      }
    });
  }

  saveNotes(question) {
    clearTimeout(this.notesTimer);
    this.notesTimer = setTimeout(() => this.saveForm(question), 3000);
  }

  userTimer;

  saveUser(question) {
    clearTimeout(this.userTimer);
    this.userTimer = setTimeout(() => this.saveForm(question), 3000);
  }

  saveForm(question) {
    this.saving = true;
    const data = {};

    data['id'] = this.surveyDetails['id'];
    data['status'] = 'started';
    data['modifiedBy'] = this.user['email'];
    // ................. eitb filter name added...................
    if (this.surveyDetails['filterName']) {
      data['filterName'] = this.surveyDetails['filterName'];
    }

    let answeredQs = 0;
    const obj = this.surveyForm.form.value;
    Object.keys(obj).map((key) => {
      if (key.indexOf('response') !== -1) {
        if (obj[key]) {
          answeredQs++;
        }
      }
    });
    if (answeredQs != 0) {
      this.surveyDetails['completion'] = answeredQs / this.surveyDetails['questions'].length;
    } else {
      this.surveyDetails['completion'] = 0;
    }

    const changes = this.differ.diff(this.surveyDetails);
    if (changes) {
      changes.forEachChangedItem((r) => {
        data[r.key] = r.currentValue;
      });
      changes.forEachAddedItem((r) => {
        data[r.key] = r.currentValue;
      });
      changes.forEachRemovedItem((r) => {
        data[r.key] = r.currentValue;
      });
    }

    if (question == 'All') {
      data['responses'] = [];

      for (let i = 0; i < this.surveyDetails['questions'].length; i++) {
        const response = {};
        if (
          this.surveyForm.form.value[
            `${this.surveyDetails['questions'][i]['id']}response`
          ]
        ) {
          this.surveyDetails['responses'][i]['response'] = this.surveyForm.form.value[
            `${this.surveyDetails['questions'][i]['id']}response`
          ];
        }

        if (
          this.surveyForm.form.value[
            `${this.surveyDetails['questions'][i]['id']}note`
          ]
        ) {
          this.surveyDetails['responses'][i]['notes'] = this.surveyForm.form.value[
            `${this.surveyDetails['questions'][i]['id']}note`
          ];
          response['notes'] = this.surveyForm.form.value[
            `${this.surveyDetails['questions'][i]['id']}note`
          ];
        }

        // if (this.surveyDetails['questions'][i]['actions']) {
        //   for (let j = 0; j < this.surveyDetails['questions'][i]['actions'].length; j++) {
        //     this.surveyDetails['questions'][i]['actions'][j]['title'] = this.unescapePipe.transform(this.surveyDetails['questions'][i]['actions'][j]['title']);
        //   }
        //   this.surveyDetails['responses'][i]['actions'] = this.surveyDetails['questions'][i]['actions'];
        // }

        // if (this.surveyDetails['questions'][i]['photos']){
        //   this.surveyDetails['responses'][i]['photos'] = this.surveyDetails['questions'][i]['photos'];
        //   this.surveyDetails['responses'][i]['photos'] = [].concat(this.surveyDetails['responses'][i]['photos']);
        // }

        if (
          this.surveyForm.form.value[
            `${this.surveyDetails['questions'][i]['id']}reviewedFrom`
          ]
        ) {
          this.surveyDetails['responses'][i]['reviewedFrom'] = moment(
            this.surveyForm.form.value[
              `${this.surveyDetails['questions'][i]['id']}reviewedFrom`
            ],
            ['DD/MM/YYYY', 'DD MMM YYYY', moment.ISO_8601]
          ).format();
        }
        if (
          this.surveyForm.form.value[
            `${this.surveyDetails['questions'][i]['id']}reviewedTo`
          ]
        ) {
          this.surveyDetails['responses'][i]['reviewedTo'] = moment(
            this.surveyForm.form.value[
              `${this.surveyDetails['questions'][i]['id']}reviewedTo`
            ],
            ['DD/MM/YYYY', 'DD MMM YYYY', moment.ISO_8601]
          ).format();
        }

        const objDiffer = this.objDiffer[this.surveyDetails['responses'][i]['question_id']];
        const objChanges = objDiffer.diff(this.surveyDetails['responses'][i]);
        if (objChanges) {
          this.surveyDetails['questions'][i]['pointInTimeReviewDate'] = this.surveyDetails['responses'][i]['pointInTimeReviewDate'];
          response['question_id'] = this.surveyDetails['questions'][i]['id'];
          if (this.surveyDetails['questions'][i]['shortTitle']) {
            this.surveyDetails['responses'][i]['shortTitle'] = this.surveyDetails['questions'][i]['shortTitle'];
          }
          objChanges.forEachChangedItem((r) => {
            if (
              r.key != 'autoSavedAction'
              && r.key != 'photos'
              && r.key != 'actions'
            ) {
              response[r.key] = r.currentValue;
            }
          });
          objChanges.forEachAddedItem((r) => {
            if (
              r.key != 'autoSavedAction'
              && r.key != 'photos'
              && r.key != 'actions'
            ) {
              response[r.key] = r.currentValue;
            }
          });
          objChanges.forEachRemovedItem((r) => {
            if (
              r.key != 'autoSavedAction'
              && r.key != 'photos'
              && r.key != 'actions'
            ) {
              response[r.key] = r.currentValue;
            }
          });

          data['responses'].push(response);
        }
      }

      if (data['responses'].length > 0) {
        const requestJSONPayload = this.csaBackEnd.attachFirewallBypassPrefixes(data, ['responses']);

        this.surveyService.saveSurvey(requestJSONPayload).subscribe(
          (response) => {
            if (response['status'] == 400) {
              this.dialog.open(MessagesComponent, {
                data: {
                  heading: "Couldn't Save Assessment",
                  message:
                    `Please email ${ 
                      this.user['supportEmail']
                    } for support`,
                  closeText: 'Ok',
                },
                backdropClass: 'dialog-backdrop',
              });
            } else {
              setTimeout(() => this.saved = true);
            }
          },
          (error) => {
            console.log(error);
            this.dialog.open(MessagesComponent, {
              data: {
                heading: 'Network Error',
                message:
                  `Please check that you have Wifi or 4g signal, and email ${ 
                    this.user['supportEmail']
                  } for support if the issue persists`,
                closeText: 'Ok',
              },
              backdropClass: 'dialog-backdrop',
            });
          }
        );
      }
    } else {
      for (let i = 0; i < this.surveyDetails['questions'].length; i++) {
        if (this.surveyDetails['questions'][i]['id'] == question) {
          data['response'] = {};
          data['response']['question_id'] = question;

          // if (this.surveyDetails['questions'][i]['actions']) {
          //   for (let j = 0; j < this.surveyDetails['questions'][i]['actions'].length; j++) {
          //     this.surveyDetails['questions'][i]['actions'][j]['title'] = this.unescapePipe.transform(this.surveyDetails['questions'][i]['actions'][j]['title']);
          //   }
          //   this.surveyDetails['responses'][i]['actions'] = this.surveyDetails['questions'][i]['actions'];
          // }

          if (this.surveyDetails['questions'][i]['shortTitle']) {
            data['response']['shortTitle'] = this.surveyDetails['questions'][i]['shortTitle'];
          }

          setTimeout(
            () => (this.surveyDetails['questions'][i]['pointInTimeReviewDate'] = moment().format()),
            10
          );

          if (this.surveyForm.form.value[`${question}reviewedFrom`]) {
            this.surveyDetails['responses'][i]['reviewedFrom'] = moment(
              this.surveyForm.form.value[`${question}reviewedFrom`],
              ['DD/MM/YYYY', 'DD MMM YYYY', moment.ISO_8601]
            ).format();
            this.minToDate = moment(
              this.surveyDetails['questions'][i]['reviewedFrom'],
              ['DD/MM/YYYY', 'DD MMM YYYY', moment.ISO_8601]
            );
          }
          if (this.surveyForm.form.value[`${question}reviewedTo`]) {
            this.surveyDetails['responses'][i]['reviewedTo'] = moment(
              this.surveyForm.form.value[`${question}reviewedTo`],
              ['DD/MM/YYYY', 'DD MMM YYYY', moment.ISO_8601]
            ).format();
          }

          // if (this.surveyDetails['questions'][i]['photos']){
          //    this.surveyDetails['responses'][i]['photos'] = this.surveyDetails['questions'][i]['photos'];
          //    this.surveyDetails['responses'][i]['photos'] = [].concat(this.surveyDetails['responses'][i]['photos']);
          //  }

          data['response']['notes'] = this.surveyDetails['questions'][i]['notes'];
          this.surveyDetails['responses'][i]['notes'] = this.surveyDetails['questions'][i]['notes'];

          this.surveyDetails['responses'][i]['pointInTimeReviewDate'] = moment().format();
          this.surveyDetails['responses'][i]['response'] = this.surveyForm.form.value[
            `${this.surveyDetails['questions'][i]['id']}response`
          ];

          const indObjDiffer = this.individualObjDiffer[
            this.surveyDetails['responses'][i]['question_id']
          ];
          const indObjChanges = indObjDiffer.diff(
            this.surveyDetails['responses'][i]
          );
          if (indObjChanges) {
            indObjChanges.forEachChangedItem((r) => {
              if (
                r.key != 'autoSavedAction'
                && r.key != 'photos'
                && r.key != 'actions'
              ) {
                data['response'][r.key] = r.currentValue;
              }
            });
            indObjChanges.forEachAddedItem((r) => {
              if (
                r.key != 'autoSavedAction'
                && r.key != 'photos'
                && r.key != 'actions'
              ) {
                data['response'][r.key] = r.currentValue;
              }
            });
            indObjChanges.forEachRemovedItem((r) => {
              if (
                r.key != 'autoSavedAction'
                && r.key != 'photos'
                && r.key != 'actions'
              ) {
                data['response'][r.key] = r.currentValue;
              }
            });
          }

          if (
            !this.surveyForm.form.value[
              `${this.surveyDetails['questions'][i]['id']}response`
            ]
          ) {
            data['response']['response'] = '';
          }
        }

        //   const changes = this.differ.diff(this.surveyDetails);
        //   if (changes) {
        //     changes.forEachChangedItem(function (r) {
        //       data[r.key] = r.currentValue;
        //     });
        //     changes.forEachAddedItem(function (r) {
        //       data[r.key] = r.currentValue;
        //     });
        //     changes.forEachRemovedItem(function (r) {
        //       data[r.key] = r.currentValue;
        //     });
        //   }
      }
       
      const requestJSONPayload = this.csaBackEnd.attachFirewallBypassPrefixes(data, ['response']);
 
      this.surveyService.saveQuestion(requestJSONPayload).subscribe(
        (response) => {
          if (response['status'] == 400) {
            this.dialog.open(MessagesComponent, {
              data: {
                heading: "Couldn't Save Question",
                message:
                  `Please email ${this.user['supportEmail']} for support`,
                closeText: 'Ok',
              },
              backdropClass: 'dialog-backdrop',
            });
          } else {
            setTimeout(() => this.saved = true);
          }
        },
        (error) => {
          console.log(error);
          this.dialog.open(MessagesComponent, {
            data: {
              heading: 'Network Error',
              message:
                `Please check that you have Wifi or 4g signal, and email ${ 
                  this.user['supportEmail']
                } for support if the issue persists`,
              closeText: 'Ok',
            },
            backdropClass: 'dialog-backdrop',
          });
        }
      );
    }
    this.checkActionsValid(this.surveyForm.form.value);
  }

  findInvalidControls() {
    this.invalid = [];
    const controls = this.surveyForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        if (name.endsWith('reviewedTo')) {
          const question = name.split('_').pop();
          const questionNumber = question.split('r').shift();
          const visualQuestionNumber = +questionNumber + 1;
          setTimeout(() => this.invalid.push(visualQuestionNumber), 0);
        }
      }
    }
  }
}
